import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import Climate from '@crehana/compass.icons.logos/Climate';
import Gestion from '@crehana/compass.icons.logos/Gestion';
import Hana from '@crehana/compass.icons.logos/Hana';
import Performance from '@crehana/compass.icons.logos/Performance';
import Reclutamiento from '@crehana/compass.icons.logos/Reclutamiento';
import Recognition from '@crehana/compass.icons.logos/Recognition';
import RemoteWork from '@crehana/compass.icons.logos/RemoteWork';
import Talent from '@crehana/compass.icons.logos/Talent';
import ActivityCheck from '@crehana/compass.icons.outline/ActivityCheck';
import Administrate from '@crehana/compass.icons.outline/Administrate';
import AppsComments from '@crehana/compass.icons.outline/AppsComments';
import Archive from '@crehana/compass.icons.outline/Archive';
import Badge from '@crehana/compass.icons.outline/Badge';
import BookOpen from '@crehana/compass.icons.outline/BookOpen';
import Building from '@crehana/compass.icons.outline/Building';
import Calendar5 from '@crehana/compass.icons.outline/Calendar';
import ColorPalette from '@crehana/compass.icons.outline/ColorPalette';
import ColorPicker from '@crehana/compass.icons.outline/ColorPicker';
import Cube from '@crehana/compass.icons.outline/Cube';
import Deadline from '@crehana/compass.icons.outline/Deadline';
import Departments from '@crehana/compass.icons.outline/Departments';
import Diploma from '@crehana/compass.icons.outline/Diploma';
import File from '@crehana/compass.icons.outline/File';
import Flag from '@crehana/compass.icons.outline/Flag';
import FolderAdd from '@crehana/compass.icons.outline/FolderAdd';
import Gift from '@crehana/compass.icons.outline/Gift';
import Globe2 from '@crehana/compass.icons.outline/Globe2';
import Grid from '@crehana/compass.icons.outline/Grid';
import Heart from '@crehana/compass.icons.outline/Heart';
import Home from '@crehana/compass.icons.outline/Home';
import Inbox from '@crehana/compass.icons.outline/Inbox';
import Megaphone from '@crehana/compass.icons.outline/Megaphone';
import Mentorship from '@crehana/compass.icons.outline/Mentorship';
import Note from '@crehana/compass.icons.outline/Note';
import OKRs from '@crehana/compass.icons.outline/OKRs';
import People from '@crehana/compass.icons.outline/People';
import Percent from '@crehana/compass.icons.outline/Percent';
import Playsquare from '@crehana/compass.icons.outline/Playsquare';
import Power from '@crehana/compass.icons.outline/Power';
import Presentation from '@crehana/compass.icons.outline/Presentation';
import Projects from '@crehana/compass.icons.outline/Projects';
import QuestionMarkCircle from '@crehana/compass.icons.outline/QuestionMarkCircle';
import CompassRanking from '@crehana/compass.icons.outline/Ranking';
import Recruitment from '@crehana/compass.icons.outline/Recruitment';
import Repeat from '@crehana/compass.icons.outline/Repeat';
import Report from '@crehana/compass.icons.outline/Report';
import Report2 from '@crehana/compass.icons.outline/Report2';
import Results from '@crehana/compass.icons.outline/Results';
import Settings2 from '@crehana/compass.icons.outline/Settings2';
import Shield from '@crehana/compass.icons.outline/Shield';
import ShoppingCart from '@crehana/compass.icons.outline/ShoppingCart';
import SkillXp from '@crehana/compass.icons.outline/SkillXp';
import Stats from '@crehana/compass.icons.outline/Stats';
import SuccessionPlan from '@crehana/compass.icons.outline/SuccessionPlan';
import TrendingUp from '@crehana/compass.icons.outline/TrendingUp';
import Learning from '@crehana/icons/logos/Elearning';
var icons = {
  logos: {
    Talent: Talent,
    Hana: Hana,
    Reclutamiento: Reclutamiento,
    Climate: Climate,
    Performance: Performance,
    Recognition: Recognition,
    Learning: Learning,
    RemoteWork: RemoteWork,
    Gestion: Gestion
  },
  outline: {
    People: People,
    Administrate: Administrate,
    Heart: Heart,
    Note: Note,
    Projects: Projects,
    Archive: Archive,
    BookOpen: BookOpen,
    Calendar5: Calendar5,
    FolderAdd: FolderAdd,
    Deadline: Deadline,
    ColorPicker: ColorPicker,
    Diploma: Diploma,
    Globe2: Globe2,
    Mentorship: Mentorship,
    Percent: Percent,
    Playsquare: Playsquare,
    CompassRanking: CompassRanking,
    SkillXp: SkillXp,
    Cube: Cube,
    ActivityCheck: ActivityCheck,
    AppsComments: AppsComments,
    Badge: Badge,
    Building: Building,
    Departments: Departments,
    File: File,
    Flag: Flag,
    Gift: Gift,
    Home: Home,
    OKRs: OKRs,
    Presentation: Presentation,
    Recruitment: Recruitment,
    Report: Report,
    Repeat: Repeat,
    Stats: Stats,
    SuccessionPlan: SuccessionPlan,
    TrendingUp: TrendingUp,
    Inbox: Inbox,
    Settings2: Settings2,
    Power: Power,
    Grid: Grid,
    Report2: Report2,
    ShoppingCart: ShoppingCart,
    Shield: Shield,
    ColorPalette: ColorPalette,
    Megaphone: Megaphone,
    Results: Results,
    QuestionMarkCircle: QuestionMarkCircle
  }
};

// icon structure should be this -> logo/Climate
export var getIcon = function getIcon(icon) {
  if (icon === undefined) return undefined;
  var _icon$split = icon.split('/'),
    _icon$split2 = _slicedToArray(_icon$split, 2),
    type = _icon$split2[0],
    iconName = _icon$split2[1];
  if (type in icons && iconName in icons[type]) {
    return icons[type][iconName];
  }
  return undefined;
};