import { useGetFeatureFlagByNameKeyQueryQuery } from "./graphql/GetFeatureFlagByNameKeyQuery.v5.generated";
/**
 * @deprecated Use `useFeatureFlag` by `@crehana/feature-flags`
 */
function useFeatureFlagByNameKey(_ref) {
  var _data$feature_flag;
  var nameKey = _ref.nameKey,
    organizationId = _ref.organizationId,
    skip = _ref.skip,
    noCache = _ref.noCache;
  var _useGetFeatureFlagByN = useGetFeatureFlagByNameKeyQueryQuery({
      fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      variables: {
        nameKey: nameKey,
        organizationId: organizationId
      },
      context: {
        clientName: 'v5'
      },
      skip: skip
    }),
    loading = _useGetFeatureFlagByN.loading,
    error = _useGetFeatureFlagByN.error,
    data = _useGetFeatureFlagByN.data;
  return {
    loading: loading,
    error: error,
    activatedFlag: Boolean(data === null || data === void 0 ? void 0 : (_data$feature_flag = data.feature_flag) === null || _data$feature_flag === void 0 ? void 0 : _data$feature_flag.value)
  };
}
export default useFeatureFlagByNameKey;