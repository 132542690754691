import { useQuery } from '@apollo/client';
var COMPASS_LAYOUTS_GET_ORGANIZATION_CONFIG_QUERY = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "CompassLayoutsGetOrganizationConfigQuery" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "slug" } }, type: { kind: "NamedType", name: { kind: "Name", value: "String" } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "organization" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "slug" }, value: { kind: "Variable", name: { kind: "Name", value: "slug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "originalId" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "configuration" }, arguments: [], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "canUseInduction" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "canUseIntegrations" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "hasGamificationAvailable" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "hasStudioAvailable" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "hasSkillXpEvolutionAvailable" }, arguments: [], directives: [] }] } }] } }] } }], loc: { start: 0, end: 279, source: { body: "query CompassLayoutsGetOrganizationConfigQuery($slug: String) {\n  organization(slug: $slug) {\n    originalId\n    configuration {\n      canUseInduction\n      canUseIntegrations\n      hasGamificationAvailable\n      hasStudioAvailable\n      hasSkillXpEvolutionAvailable\n    }\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useConfiguration = function useConfiguration(_ref) {
  var _data$organization, _data$organization2, _data$organization2$c, _data$organization3, _data$organization3$c, _data$organization4, _data$organization4$c, _data$organization5, _data$organization5$c, _data$organization6, _data$organization6$c;
  var organizationSlug = _ref.organizationSlug;
  var _useQuery = useQuery(COMPASS_LAYOUTS_GET_ORGANIZATION_CONFIG_QUERY, {
      variables: {
        slug: organizationSlug
      },
      context: {
        clientName: 'b2b'
      },
      skip: !organizationSlug
    }),
    data = _useQuery.data,
    loading = _useQuery.loading;
  return {
    organizationId: (data === null || data === void 0 ? void 0 : (_data$organization = data.organization) === null || _data$organization === void 0 ? void 0 : _data$organization.originalId) || 0,
    isLoadingConfig: loading,
    organizationSlug: organizationSlug,
    canUseInduction: (data === null || data === void 0 ? void 0 : (_data$organization2 = data.organization) === null || _data$organization2 === void 0 ? void 0 : (_data$organization2$c = _data$organization2.configuration) === null || _data$organization2$c === void 0 ? void 0 : _data$organization2$c.canUseInduction) || false,
    canUseIntegrations: (data === null || data === void 0 ? void 0 : (_data$organization3 = data.organization) === null || _data$organization3 === void 0 ? void 0 : (_data$organization3$c = _data$organization3.configuration) === null || _data$organization3$c === void 0 ? void 0 : _data$organization3$c.canUseIntegrations) || false,
    canUseElevate: (data === null || data === void 0 ? void 0 : (_data$organization4 = data.organization) === null || _data$organization4 === void 0 ? void 0 : (_data$organization4$c = _data$organization4.configuration) === null || _data$organization4$c === void 0 ? void 0 : _data$organization4$c.hasStudioAvailable) || false,
    canUseGamification: (data === null || data === void 0 ? void 0 : (_data$organization5 = data.organization) === null || _data$organization5 === void 0 ? void 0 : (_data$organization5$c = _data$organization5.configuration) === null || _data$organization5$c === void 0 ? void 0 : _data$organization5$c.hasGamificationAvailable) || false,
    canUseSkillXP: (data === null || data === void 0 ? void 0 : (_data$organization6 = data.organization) === null || _data$organization6 === void 0 ? void 0 : (_data$organization6$c = _data$organization6.configuration) === null || _data$organization6$c === void 0 ? void 0 : _data$organization6$c.hasSkillXpEvolutionAvailable) || false
  };
};
export default useConfiguration;