import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { HomeEmptyBaseModule } from "./hooks/useTalentModules/constants";
export var addProductsLabelSeparatorSubtitle = function addProductsLabelSeparatorSubtitle(productsList, subtitle) {
  for (var i = 0; i < productsList.length; i++) {
    if (productsList[i].isProductModule) {
      productsList[i].subtitle = subtitle;
      break;
    }
  }
  return productsList;
};

// This functions adds the Home module if does not exist at the backend data
// This is very important to structure the data correctly
export var addHomeTalentModule = function addHomeTalentModule(talentModules) {
  if (talentModules.find(function (module) {
    return module.code !== 'HOME';
  })) {
    return [HomeEmptyBaseModule].concat(_toConsumableArray(talentModules));
  }
  return talentModules;
};