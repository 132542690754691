import { useShallow } from 'zustand/react/shallow';
import useFeatureFlagStoreContext from "../useFeatureFlagStoreContext";

/**
 * @description Get the full object of a feature flag, including the flag value, name, loading state, etc.
 */
var useFeatureFlag = function useFeatureFlag(featureFlag) {
  var apiVersion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'V5';
  return useFeatureFlagStoreContext(useShallow(function (state) {
    return state.getFlagAndRegister(featureFlag, apiVersion);
  }));
};
export default useFeatureFlag;