import { useCompassLayoutsGetUserB2BVerificationQuery } from "./graphql/get-user-b2b-verification.v4.b2b.generated";
export var useOrganizationSlug = function useOrganizationSlug(_ref) {
  var _data$userB2bVerifica, _data$userB2bVerifica2, _data$userB2bVerifica3, _data$userB2bVerifica4, _data$userB2bVerifica5, _data$userB2bVerifica6;
  var email = _ref.email;
  var _useCompassLayoutsGet = useCompassLayoutsGetUserB2BVerificationQuery({
      variables: {
        email: email
      },
      context: {
        clientName: 'v4.b2b'
      },
      skip: !email
    }),
    data = _useCompassLayoutsGet.data,
    loading = _useCompassLayoutsGet.loading;
  return {
    organizationSlug: (_data$userB2bVerifica = data === null || data === void 0 ? void 0 : (_data$userB2bVerifica2 = data.userB2bVerification) === null || _data$userB2bVerifica2 === void 0 ? void 0 : _data$userB2bVerifica2.orgSlug) !== null && _data$userB2bVerifica !== void 0 ? _data$userB2bVerifica : '',
    myRole: (_data$userB2bVerifica3 = data === null || data === void 0 ? void 0 : (_data$userB2bVerifica4 = data.userB2bVerification) === null || _data$userB2bVerifica4 === void 0 ? void 0 : _data$userB2bVerifica4.role) !== null && _data$userB2bVerifica3 !== void 0 ? _data$userB2bVerifica3 : '',
    isB2BAllCatalog: (data === null || data === void 0 ? void 0 : (_data$userB2bVerifica5 = data.userB2bVerification) === null || _data$userB2bVerifica5 === void 0 ? void 0 : _data$userB2bVerifica5.assignedType) === 1,
    isB2BOnlyPanel: (data === null || data === void 0 ? void 0 : (_data$userB2bVerifica6 = data.userB2bVerification) === null || _data$userB2bVerifica6 === void 0 ? void 0 : _data$userB2bVerifica6.assignedType) === 2,
    isLoading: loading
  };
};