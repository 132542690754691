import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var OrganizationAccessLevelQueryDocument = {
  kind: 'Document',
  definitions: [{
    kind: 'OperationDefinition',
    operation: 'query',
    name: {
      kind: 'Name',
      value: 'OrganizationAccessLevelQuery'
    },
    variableDefinitions: [{
      kind: 'VariableDefinition',
      variable: {
        kind: 'Variable',
        name: {
          kind: 'Name',
          value: 'input'
        }
      },
      type: {
        kind: 'NonNullType',
        type: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'OrganizationBaseInput'
          }
        }
      }
    }],
    selectionSet: {
      kind: 'SelectionSet',
      selections: [{
        kind: 'Field',
        name: {
          kind: 'Name',
          value: 'content_organization'
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [{
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_access_level'
            },
            arguments: [{
              kind: 'Argument',
              name: {
                kind: 'Name',
                value: 'input'
              },
              value: {
                kind: 'Variable',
                name: {
                  kind: 'Name',
                  value: 'input'
                }
              }
            }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{
                kind: 'Field',
                name: {
                  kind: 'Name',
                  value: 'organization_id'
                }
              }, {
                kind: 'Field',
                name: {
                  kind: 'Name',
                  value: 'access_level_enum'
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useOrganizationAccessLevelQueryQuery__
 *
 * To run a query within a React component, call `useOrganizationAccessLevelQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAccessLevelQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAccessLevelQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationAccessLevelQueryQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(OrganizationAccessLevelQueryDocument, options);
}
export function useOrganizationAccessLevelQueryLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(OrganizationAccessLevelQueryDocument, options);
}