import { useMemo } from 'react';
import { useOrganizationAccessLevel } from '@crehana/compass.knowledgehub';
import useIsPlayRoomActivated from '@crehana/compass.playroom/shared/hooks/useIsPlayRoomActivated';
import { useFlagOrganization } from "../../../../hooks/useFlagOrganization";
import { useLearning } from "../use-learning";
export var useExcludedCodes = function useExcludedCodes(props) {
  var organizationSlug = props.organizationSlug,
    organizationId = props.organizationId,
    email = props.email,
    isKnowledgeHubEnabled = props.isKnowledgeHubEnabled,
    isLiveSessionFF = props.isLiveSessionFF,
    isLearningAccessLevelLMS = props.isLearningAccessLevelLMS;
  var _useIsPlayRoomActivat = useIsPlayRoomActivated(organizationSlug),
    isPlayroomEnabled = _useIsPlayRoomActivat.isActivated;
  var _useLearning = useLearning({
      email: email
    }),
    myRole = _useLearning.myRole,
    isB2BAllCatalog = _useLearning.isB2BAllCatalog;
  var _useFlagOrganization = useFlagOrganization({
      flag: 'NEW_HIGHER_EDUCATION_HOME_USER',
      organizationSlug: organizationSlug
    }),
    hideMarketplaceUIFF = _useFlagOrganization.isActive,
    loadingMarketplace = _useFlagOrganization.isLoading;
  var _useOrganizationAcces = useOrganizationAccessLevel({
      orgId: organizationId,
      skip: !isLearningAccessLevelLMS || organizationId === -1
    }),
    isPureLMSOrganization = _useOrganizationAcces.isPureLMSOrganization,
    isLoadingPureLMSOrganization = _useOrganizationAcces.loading;
  var isMarketplaceUser = myRole === 'high-education-member';
  var hideMarketplaceNavbarMenu = isMarketplaceUser && hideMarketplaceUIFF;
  var shouldHideMentorship = isLearningAccessLevelLMS && isPureLMSOrganization;
  var isLoading = loadingMarketplace || isLoadingPureLMSOrganization;
  var codesToExclude = useMemo(function () {
    var excludedCodes = [];
    if (!(isKnowledgeHubEnabled && isB2BAllCatalog)) excludedCodes.push('LEARNING.CATALOG');
    if (!isLiveSessionFF) excludedCodes.push('LEARNING.COURSES.LIVE_SESSIONS');
    if (!isPlayroomEnabled) excludedCodes.push('LEARNING.GAMIFICATION');
    if (shouldHideMentorship) excludedCodes.push('LEARNING.MENTORSHIP');
    if (hideMarketplaceNavbarMenu) excludedCodes.push('LEARNING.SKILL_XP_EVOLUTION');
    return excludedCodes;
  }, [isKnowledgeHubEnabled, isB2BAllCatalog, isLiveSessionFF, isPlayroomEnabled, shouldHideMentorship, hideMarketplaceNavbarMenu]);
  return {
    codesToExclude: codesToExclude,
    isLoading: isLoading
  };
};