/* eslint-disable no-restricted-properties */

var customSessionStorage = {
  clear: function clear() {
    try {
      window.sessionStorage.clear();
    } catch (error) {
      console.error('CustomSessionStorage: Error to clear the sessionStorage', error);
    }
  },
  getItem: function getItem(key) {
    try {
      return window.sessionStorage.getItem(key);
    } catch (error) {
      console.error("CustomSessionStorage: Error to get the value for the key ".concat(key), error);
      return null;
    }
  },
  removeItem: function removeItem(key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.error("CustomSessionStorage: Error to remove the value for the key ".concat(key), error);
    }
  },
  setItem: function setItem(key, value) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (error) {
      console.error("CustomSessionStorage: Error to set the value for the key ".concat(key), error);
    }
  }
};
export default customSessionStorage;