import { GlobalStyles } from './B2BOrgLayout.styles';
import type { B2BOrgLayoutProps } from './types';

const B2BOrgLayout: React.FC<B2BOrgLayoutProps> = ({ orgInfo, children }) => (
  <>
    <GlobalStyles
      primaryColor={orgInfo.lookAndFeel?.primary?.main || undefined}
      secondaryColor={orgInfo.lookAndFeel?.secondary?.main || undefined}
    />

    {children}
  </>
);

export default B2BOrgLayout;
