import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var LibraryCrehanaAuthGetCentralizedDataDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "LibraryCrehanaAuthGetCentralizedData"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "user_email"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "organization_slug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "get_user_organization"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "user_email"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "user_email"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "user_id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "role"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "organization"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "alias": {
                  "kind": "Name",
                  "value": "id"
                },
                "name": {
                  "kind": "Name",
                  "value": "original_id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "original_id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "slug"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "get_organization_by_learning_slug"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "organization_slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "organization_slug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "id"
            },
            "name": {
              "kind": "Name",
              "value": "original_id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "original_id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useLibraryCrehanaAuthGetCentralizedDataQuery__
 *
 * To run a query within a React component, call `useLibraryCrehanaAuthGetCentralizedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryCrehanaAuthGetCentralizedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryCrehanaAuthGetCentralizedDataQuery({
 *   variables: {
 *      user_email: // value for 'user_email'
 *      organization_slug: // value for 'organization_slug'
 *   },
 * });
 */
export function useLibraryCrehanaAuthGetCentralizedDataQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(LibraryCrehanaAuthGetCentralizedDataDocument, options);
}
export function useLibraryCrehanaAuthGetCentralizedDataLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(LibraryCrehanaAuthGetCentralizedDataDocument, options);
}