import { addMissingModules } from "../../../utils";
import { EmptyTalentModulesArray } from "../constants";
import { useCompassLayoutsGetAllowedTalentModulesQuery } from "./grapqhql/compass-layouts-get-allowed-talent-modules.v5.generated";
import { useCentralizedOrgSlug } from "./use-centralized-org-slug";
export var useGetModulesByOrganizationSlug = function useGetModulesByOrganizationSlug(_ref) {
  var _data$get_allowed_mod, _data$get_allowed_mod2;
  var organizationSlug = _ref.organizationSlug,
    email = _ref.email;
  // Get the centralizedOrgSlug with the regular orgSlug
  var _useCentralizedOrgSlu = useCentralizedOrgSlug({
      organizationSlug: organizationSlug || ''
    }),
    centralizedOrgSlug = _useCentralizedOrgSlu.centralizedOrgSlug,
    loadingCentralizedOrgSlug = _useCentralizedOrgSlu.loading;
  var _useCompassLayoutsGet = useCompassLayoutsGetAllowedTalentModulesQuery({
      context: {
        clientName: 'v5'
      },
      variables: {
        email: email,
        organizationSlug: centralizedOrgSlug
      },
      skip: !organizationSlug || !centralizedOrgSlug || loadingCentralizedOrgSlug || !email
    }),
    data = _useCompassLayoutsGet.data,
    loadingTalentModules = _useCompassLayoutsGet.loading,
    error = _useCompassLayoutsGet.error;
  if (error) console.error(error);
  if (!organizationSlug || error) {
    return {
      adminModules: EmptyTalentModulesArray,
      collaboratorModules: EmptyTalentModulesArray
    };
  }
  return {
    adminModules: getModules(data === null || data === void 0 ? void 0 : (_data$get_allowed_mod = data.get_allowed_modules) === null || _data$get_allowed_mod === void 0 ? void 0 : _data$get_allowed_mod.modules_admin),
    collaboratorModules: getModules(data === null || data === void 0 ? void 0 : (_data$get_allowed_mod2 = data.get_allowed_modules) === null || _data$get_allowed_mod2 === void 0 ? void 0 : _data$get_allowed_mod2.modules),
    loading: loadingCentralizedOrgSlug || loadingTalentModules
  };
};
var getModules = function getModules(modules) {
  return modules ? addMissingModules({
    talentModules: modules
  }) : EmptyTalentModulesArray;
};