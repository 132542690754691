import { useQuery } from '@apollo/client';

import AUTH_PAGES_ORG_INFO_QUERY from './grahpql/LearnDashboardOrgInfoQuery.b2b.graphql';
import type {
  LearnDashboardOrgInfoQuery,
  LearnDashboardOrgInfoQueryVariables,
} from './grahpql/types/LearnDashboardOrgInfoQuery';

interface GetOrganizationThemeProps {
  organizationSlug: string;
  skip?: boolean;
}

const useOrganizationTheme = ({
  organizationSlug,
  skip,
}: GetOrganizationThemeProps) => {
  const { data, loading, error, called } = useQuery<
    LearnDashboardOrgInfoQuery,
    LearnDashboardOrgInfoQueryVariables
  >(AUTH_PAGES_ORG_INFO_QUERY, {
    context: { clientName: 'b2b' },
    variables: {
      slug: organizationSlug,
    },
    skip: skip || !organizationSlug,
  });

  return {
    organizationInfo: data?.organizationInfo || undefined,
    loading,
    error,
    called,
  };
};

export default useOrganizationTheme;
