import fetchFlag from "../services/fetchFlag";
/**
 * @description Fetch all flags from the server.
 */
export default function fetchArrayFlags(flags, organizationId) {
  var promises = flags.map(function (flag) {
    return fetchFlag(flag.flagName, flag.apiVersion, {
      organizationId: organizationId
    });
  });
  return Promise.all(promises);
}