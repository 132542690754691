import { useGetModulesByOrganizationSlug } from "./useGetModulesByOrganizationSlug";

/*
  This hooks handles the logic to get the modules using organizationSlug or companyId based on the available data
*/
var useTalentModules = function useTalentModules(_ref) {
  var email = _ref.email,
    organizationSlug = _ref.organizationSlug;
  var modulesSorceData1 = useGetModulesByOrganizationSlug({
    email: email,
    organizationSlug: organizationSlug
  });
  return modulesSorceData1;
};
export default useTalentModules;