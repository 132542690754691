/*
 * Docker API URLs
 * There is an special consideration for QA demo URlS
 * We have 2 urls that are needed for different cases based on
 * some backend requirements.
 * IMPORTANT: THE QA urls are not interchangeable, do not swap just because you can. Ask the backend before.
 */
var PROD_BASE_URL = 'https://docker-hr.acsendo.com';
var QA_PRUEBAS_BASE_URL = 'https://docker-pruebas.acsendo.com';
// @ts-expect-error TODO: This const will be used later
var QA_DEMO_BASE_URL = 'https://docker-pruebas.acsendo.com';
/* ***** */

export var isProduction = process.env.APP_ENV === 'production';
export var TALENT_DOCKER_API_URL = isProduction ? PROD_BASE_URL : QA_PRUEBAS_BASE_URL;