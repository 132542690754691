var homeUrls = ['/org/[slug]/panel/inicio/', '/org/[slug]/panel/'];
var learningUrls = ['/org/[slug]/panel/content/courses/', '/org/[slug]/panel/content/paths/', '/org/[slug]/panel/content/projects/', '/org/[slug]/panel/skill-xp-evolution/lanzados/', '/org/[slug]/panel/gamification/progress/', '/org/[slug]/panel/gamification/rewards/', '/org/[slug]/panel/mi-tienda/', '/org/[slug]/panel/integraciones/descubre/', '/org/[slug]/panel/integraciones/mis-integraciones/', '/org/[slug]/panel/integraciones/credenciales/', '/org/[slug]/panel/estadisticas/generales/'];
var adminUrls = ['/org/[slug]/panel/talent-suite/compania/campos-personalizados/', '/org/[slug]/panel/talent-suite/usuarios/invitados/', '/org/[slug]/panel/talent-suite/usuarios/por-invitar/', '/org/[slug]/panel/talent-suite/cuenta/informacion/', '/org/[slug]/panel/talent-suite/cuenta/licencias/', '/org/[slug]/panel/talent-suite/cuenta/roles/', '/org/[slug]/panel/talent-suite/configuracion/apariencia/', '/org/[slug]/panel/talent-suite/configuracion/comunicaciones/', '/org/[slug]/panel/administrar/automatizaciones/', '/org/[slug]/admin/company/custom-fields', '/org/[slug]/admin/company/users/invited', '/org/[slug]/admin/company/users/to-invite', '/org/[slug]/admin/account/information', '/org/[slug]/admin/account/roles', '/org/[slug]/admin/appearance/desktop', '/org/[slug]/admin/appearance/apps', '/org/[slug]/admin/communication'];
var analyticUrls = ['/org/[slug]/panel/estadisticas/', '/org/[slug]/panel/estadisticas/generales/', '/org/[slug]/panel/reportes/', '/org/[slug]/panel/reportes/publicados/'];
var getUrls = function getUrls(urls, organizationSlug) {
  return urls.map(function (url) {
    if (url.includes('[slug]')) {
      return url.replace('[slug]', organizationSlug);
    }
    return url;
  });
};
export var getLearningUrls = function getLearningUrls(organizationSlug) {
  return getUrls(learningUrls, organizationSlug);
};
export var getAdminUrls = function getAdminUrls(organizationSlug) {
  return getUrls(adminUrls, organizationSlug);
};
export var getAnalyticUrls = function getAnalyticUrls(organizationSlug) {
  return getUrls(analyticUrls, organizationSlug);
};
export var getHomeUrls = function getHomeUrls(organizationSlug) {
  return getUrls(homeUrls, organizationSlug);
};