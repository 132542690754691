import useB2BRoutes from "./useB2bRoutes";
var useLearningRoutes = function useLearningRoutes(_ref) {
  var isLearningModuleActive = _ref.isLearningModuleActive,
    organizationSlug = _ref.organizationSlug,
    productPlatform = _ref.productPlatform,
    email = _ref.email,
    organizationId = _ref.organizationId;
  var _useB2BRoutes = useB2BRoutes({
      organizationSlug: organizationSlug,
      productPlatform: productPlatform,
      email: email,
      organizationId: organizationId
    }),
    b2bRoutes = _useB2BRoutes.b2bRoutes,
    isKnowledgeHubEnabled = _useB2BRoutes.isKnowledgeHubEnabled;
  if (!isLearningModuleActive) {
    return {
      links: [],
      defaultLink: undefined,
      isKnowledgeHubEnabled: isKnowledgeHubEnabled
    };
  }
  return {
    links: b2bRoutes,
    defaultLink: b2bRoutes[0],
    isKnowledgeHubEnabled: isKnowledgeHubEnabled
  };
};
export default useLearningRoutes;