export var MODULE_CODE_ADMIN = {
  HOME: 'HOME',
  MAIN_ADMIN_ANALYTICS: 'MAIN_ADMIN_ANALYTICS',
  HANA_AI: 'HANA_AI',
  RECOGNITION: 'RECOGNITION',
  PERFORMANCE: 'PERFORMANCE',
  CLIMATE_ENGAGEMENT: 'CLIMATE_ENGAGEMENT',
  E_LEARNING: 'E-Learning',
  ADMIN: 'ADMIN'
};
export var SIDEBAR_TYPE = {
  admin: 'admin',
  collaborator: 'collaborator'
};