import type { IntercomProps } from 'react-use-intercom';

import type { MeObject } from '@crehana/auth';

/**
 * It returns true if the current URL is a QA URL, and false otherwise
 * @returns A boolean value.
 */
export function isQA() {
  const locationOrigin = window.location.origin;
  return (
    locationOrigin.includes('qa2.creha.co') ||
    locationOrigin.includes('qa.creha.co')
  );
}

/**
 * It takes in a few boolean values and a user object, and returns an object that contains the user's
 * information and some other data
 * @param {boolean} hasElevate - boolean - Whether the user has access to Elevate
 * @param {boolean} hasPlayroomActivated - boolean - whether the user has activated the Playroom
 * @param {Me} user - Me - this is the user object that you get from the API.
 */
const customIntercomConfiguration = (
  hasElevate: boolean,
  hasPlayroomActivated: boolean,
  user: MeObject,
): IntercomProps | undefined => ({
  userId: user?.id,
  name: user?.displayName!,
  email: user?.email!,
  customAttributes: {
    has_enrollments: !!user?.hasEnrollment,
    has_active_degree: user?.isActiveDegree,
    is_b2b: user?.isB2B,
    is_premium: user?.isPremium,
    has_playroom_activated: hasPlayroomActivated,
    has_elevate: hasElevate,
  },
  phone:
    user?.phoneCode && user?.phoneNumber
      ? `+${user.phoneCode} ${user.phoneNumber}`
      : undefined,
  avatar: {
    type: 'avatar',
    // @ts-expect-error
    image_url: user?.profilePictureUrl,
  },
  company: user?.organization
    ? {
        // @ts-expect-error
        companyId: user.organization.originalId.toString(),
        name: user.organization.name,
      }
    : undefined,
});

export { customIntercomConfiguration };
