import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { HomeEmptyBaseModule } from "./hooks/use-talent-modules/constants";
// This functions adds the Home module if does not exist at the backend data
// This is very important to structure the data correctly
export var addMissingModules = function addMissingModules(_ref) {
  var talentModules = _ref.talentModules;
  return [].concat(_toConsumableArray(talentModules.find(function (module) {
    return module.code !== 'HOME';
  }) ? [HomeEmptyBaseModule] : []), _toConsumableArray(talentModules));
};

/**
 * Recursively searches for a menu link by code.
 *
 * @param {IMenuLink[]} menuLinks - The array of menu links.
 * @param {string} code - The code to search for.
 * @returns {IMenuLink | undefined} - The menu link with the given code, or undefined if not found.
 */
var _findMenuLinkByCode = function findMenuLinkByCode(menuLinks, code) {
  var _iterator = _createForOfIteratorHelper(menuLinks),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var link = _step.value;
      if (link.code === code) {
        return link;
      }
      if (link.children && link.children.length > 0) {
        var found = _findMenuLinkByCode(link.children, code);
        if (found) {
          return found;
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return null;
};
export { _findMenuLinkByCode as findMenuLinkByCode };