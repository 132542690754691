// IMPORTANT:
// At the FULL_SUITE_ADMIN_LINKS there are codes that are marked
// with "pending" that are not map yet with the backend code.

import { GENERAL_SETTINGS_MODULE, HELP_LINK_ADMIN } from "../shared";
export var FULL_SUITE_ADMIN_LINKS = {
  MENU: [
  // Home
  {
    type: 'url',
    code: 'HOME',
    label: 'MODULE_HOME',
    icon: 'outline/Home',
    url: '/org/[orgSlug]/panel/inicio/',
    tooltip: 'Inicio'
  },
  // Analytics
  {
    type: 'module',
    code: 'MAIN_ADMIN_ANALYTICS',
    label: 'Analítica',
    icon: 'outline/Stats',
    arrowDefault: true,
    accentedColor: false,
    tooltip: {
      title: 'Analítica',
      description: 'Monitorea tus procesos y genera reportes.'
    },
    children: [{
      type: 'dropdown',
      code: 'MAIN_ADMIN_ANALYTICS.ADMIN_ANALYTICS',
      label: 'Estadísticas',
      icon: 'outline/Presentation',
      tooltip: {
        title: 'Estadísticas',
        description: 'Visualiza las métricas de tus procesos internos.'
      },
      children: [
      // Statistics Management
      {
        type: 'url',
        label: 'Gestión',
        code: 'TBD'
        // url: 'https://hr.acsendo.com/results/admin',
      },
      // Talent
      {
        type: 'url',
        label: 'Talent',
        code: 'MAIN_ADMIN_ANALYTICS.ADMIN_ANALYTICS.Talent'
      },
      // Learning
      {
        type: 'url',
        label: 'E-learning',
        code: 'MAIN_ADMIN_ANALYTICS.ADMIN_ANALYTICS.E-Learning',
        url: '/org/[orgSlug]/panel/estadisticas/'
      }]
    },
    // Reports
    {
      type: 'dropdown',
      code: 'MAIN_ADMIN_ANALYTICS.ADMIN_REPORTS_ANALYTICS',
      label: 'Reportes',
      icon: 'outline/Report2',
      tooltip: {
        title: 'Reportes',
        description: 'Genera informes personalizados.'
      },
      children: [
      // Talent
      {
        type: 'url',
        label: 'Talent',
        code: 'MAIN_ADMIN_ANALYTICS.ADMIN_REPORTS_ANALYTICS.Talent'
      },
      // Learning
      {
        type: 'url',
        label: 'E-learning',
        code: 'MAIN_ADMIN_ANALYTICS.ADMIN_REPORTS_ANALYTICS.E-Learning',
        url: '/org/[orgSlug]/panel/reportes/'
      }]
    },
    // N-Box
    {
      type: 'url',
      // Pending
      code: 'MAIN_ADMIN_ANALYTICS.NBOX',
      label: 'N-box',
      icon: 'outline/Grid',
      tooltip: {
        title: 'N-box',
        description: 'Crea matrices cruzando variables para visualizar datos.'
      }
    }]
  }, {
    type: 'group',
    code: 'PRODUCTS_MODULE',
    label: 'Productos',
    children: [
    // Hana AI
    {
      type: 'url',
      code: 'HANA_AI',
      label: 'Hana',
      icon: 'logos/Hana',
      url: '/org/[orgSlug]/panel/hana-ai/',
      decoratedIcon: true,
      tooltip: {
        title: 'Hana AI',
        description: 'Analiza la data de tu compañía con Inteligencia Artificial.'
      }
    },
    // Management
    {
      type: 'module',
      code: 'ADMIN',
      label: 'Gestión',
      arrowDefault: true,
      decoratedIcon: true,
      icon: 'logos/Gestion',
      tooltip: {
        title: 'Gestión',
        description: 'Gestiona tu compañía, documentos y solicitudes de tus colaboradores.'
      },
      children: [
      // Company
      {
        type: 'dropdown',
        code: 'ADMIN.COMPANYPAR',
        label: 'Compañía',
        icon: 'outline/Building',
        tooltip: {
          title: 'Compañía',
          description: 'Crea usuarios y gestiona la estructura organizacional.'
        },
        children: [
        // Users
        {
          type: 'url',
          code: 'ADMIN.COMPANYPAR.USERS_COMPANY',
          label: 'Usuarios',
          url: '/org/[orgSlug]/admin/company/users/'
        },
        // Chart
        {
          type: 'url',
          code: 'ADMIN.COMPANYPAR.ORGCHART_COMPANY',
          label: 'Organigrama',
          url: '/org/[orgSlug]/admin/hris/org-chart/'
        },
        // Areas and quarters
        {
          type: 'url',
          code: 'ADMIN.COMPANYPAR.DIVISION_COMPANY',
          label: 'Departamentos'
        }, {
          type: 'url',
          code: 'ADMIN.COMPANYPAR.AREAS_AND_QUARTERS',
          label: 'Áreas y sedes',
          url: '/org/[orgSlug]/admin/company/structure/'
        },
        // Projects matrix
        // {
        //   type: 'url',
        //   code: 'MODULE_MANAGEMENT.COMPANY.PROJECTS_MATRIX',
        //   label: 'Matrices de proyectos',
        //   url: '/tbd',
        // },
        // Job roles
        {
          type: 'url',
          code: 'ADMIN.COMPANYPAR.JOBS_COMPANY',
          label: 'Cargos y categorías'
        },
        // Custom attributes
        {
          type: 'url',
          code: 'ADMIN.COMPANYPAR.EXTRA_FIELDS_COMPANY',
          label: 'Atributos personalizados'
        }]
      },
      // Corporative documents
      {
        type: 'url',
        code: 'MODULE_MANAGEMENT.CORPORATIVE_DOCUMENTS',
        label: 'Documentos corporativos',
        icon: 'outline/FolderAdd',
        url: '/org/[orgSlug]/admin/corporate-documents/',
        tooltip: {
          title: 'Documentos corporativos',
          description: 'Centraliza documentos confidenciales y solicita firmas electrónicas.'
        }
      },
      // Time off
      {
        type: 'dropdown',
        // Pending and also all it's children 'cause they are in devlopment by the Q4 HRIS team
        code: 'MODULE_MANAGEMENT.TIME_OFF',
        label: 'Tiempo libre',
        icon: 'outline/Deadline',
        tooltip: {
          title: 'Tiempo libre',
          description: 'Gestiona las políticas de tiempo libre y los feriados de tu compañía.'
        },
        children: [
        // Policies
        {
          type: 'url',
          label: 'Políticas',
          code: 'MODULE_MANAGEMENT.TIME_OFF.POLICIES',
          url: '/org/[orgSlug]/admin/free-time/policies/'
        },
        // Requests
        {
          type: 'url',
          label: 'Solicitudes',
          code: 'MODULE_MANAGEMENT.TIME_OFF.REQUESTS',
          url: '/org/[orgSlug]/admin/free-time/requests/'
        },
        // Holidays
        {
          type: 'url',
          label: 'Feriados',
          code: 'MODULE_MANAGEMENT.TIME_OFF.HOLIDAYS',
          url: '/org/[orgSlug]/admin/hris/time-off/holidays/'
        },
        // Calendar
        {
          type: 'url',
          label: 'Calendario',
          code: 'MODULE_MANAGEMENT.TIME_OFF.CALENDAR',
          url: '/org/[orgSlug]/admin/hris/time-off/calendar/'
        },
        // Configuration
        {
          type: 'url',
          label: 'Configuración',
          code: 'MODULE_MANAGEMENT.TIME_OFF.CONFIGURATION',
          url: '/tbd'
        }]
      },
      // Automations
      {
        type: 'dropdown',
        code: 'MODULE_MANAGEMENT.AUTOMATIONS',
        label: 'Automatizaciones',
        icon: 'outline/Repeat',
        tooltip: {
          title: 'Automatizaciones',
          description: 'Crea reglas de automatización para agilizar tareas.'
        },
        children: [
        // Workflows
        {
          type: 'url',
          code: 'MODULE_MANAGEMENT.AUTOMATIONS.WORKFLOWS',
          label: 'Flujos de trabajo',
          url: '/org/[orgSlug]/admin/automations/workflows/'
        },
        // Tasks assignment
        {
          type: 'url',
          label: 'Asignación de aprendizaje',
          code: 'ADMIN.AUTOMATIONS_ADMIN',
          url: "/org/[orgSlug]/panel/administrar/automatizaciones/"
        }]
      }]
    },
    // Recognition
    {
      type: 'module',
      code: 'RECOGNITION',
      label: 'Reconocimiento',
      arrowDefault: true,
      decoratedIcon: true,
      icon: 'logos/Recognition',
      tooltip: {
        title: 'Reconocimiento',
        description: 'Gestiona las reglas de reconocimiento y las solicitudes de los colaboradores.'
      },
      children: [
      // Results
      {
        type: 'url',
        code: 'RECOGNITION.RECOGNITION_RESULTS',
        label: 'Resultados',
        icon: 'outline/Presentation',
        tooltip: {
          title: 'Resultados',
          description: 'Monitorea el ranking de medallas obtenidas por tus colaboradores.'
        }
      },
      // Medal requests
      {
        type: 'url',
        code: 'RECOGNITION.RECOGNITION_REQUESTS',
        label: 'Solicitud de medallas',
        icon: 'outline/Inbox',
        tooltip: {
          title: 'Solicitud de medallas',
          description: 'Aprueba o rechaza las medallas que se envían los colaboradores.'
        }
      },
      // Rewards
      {
        type: 'url',
        code: 'RECOGNITION.RECOGNITION_REDEMPTION',
        label: 'Recompensas',
        icon: 'outline/Gift',
        tooltip: {
          title: 'Recompensas',
          description: 'Gestiona y entrega las recompensas canjeadas.'
        }
      },
      // Configuration
      {
        type: 'dropdown',
        code: 'RECOGNITION.RECOGNITION_CONFIGURATION',
        label: 'Configuración',
        icon: 'outline/Settings2',
        tooltip: {
          title: 'Configuración',
          description: 'Crea medallas, define reglas y mensajes.'
        },
        children: [
        // Medals
        {
          type: 'url',
          label: 'Medallas',
          code: 'RECOGNITION.RECOGNITION_CONFIGURATION.RECOGNITION_CONFIG_MEDALS'
        },
        // Messages
        {
          type: 'url',
          label: 'Mensajes',
          code: 'RECOGNITION.RECOGNITION_CONFIGURATION.RECOGNITION_CONFIG_MESSAGE'
        },
        // Rules and permissions
        {
          type: 'url',
          label: 'Reglas y permisos',
          code: 'RECOGNITION.RECOGNITION_CONFIGURATION.RECOGNITION_CONFIG_RULES'
        }]
      }]
    },
    // Performance
    {
      type: 'module',
      code: 'PERFORMANCE',
      label: 'Desempeño',
      icon: 'logos/Performance',
      arrowDefault: true,
      decoratedIcon: true,
      tooltip: {
        title: 'Desempeño',
        description: 'Evalúa el rendimiento, a través de objetivos y competencias.'
      },
      children: [
      // Results
      {
        type: 'url',
        code: 'PERFORMANCE.RESULTS_PERFORMANCE',
        label: 'Resultados',
        icon: 'outline/Presentation',
        tooltip: {
          title: 'Resultados',
          description: 'Mide el desempeño y el compromiso de los equipos.'
        }
      },
      // Competences
      {
        type: 'url',
        code: 'PERFORMANCE.COMPETENCES',
        label: 'Competencias',
        icon: 'outline/Report',
        tooltip: {
          title: 'Competencias',
          description: 'Crea y asigna evaluaciones de competencias.'
        }
      },
      // Goals
      {
        type: 'url',
        code: 'PERFORMANCE.GOALS',
        label: 'Metas',
        icon: 'outline/Flag',
        tooltip: {
          title: 'Metas',
          description: 'Crea y asigna metas de desempeño.'
        }
      },
      // OKRs
      {
        type: 'dropdown',
        code: 'PERFORMANCE.OKRS_PERFORMANCE',
        label: 'OKRs',
        icon: 'outline/OKRs',
        tooltip: {
          title: 'OKRs',
          description: 'Crea y asigna objetivos de desempeño.'
        },
        children: [
        // OKRs
        {
          type: 'url',
          label: 'OKRs',
          code: 'PERFORMANCE.OKRS_PERFORMANCE.OKRS'
        },
        // Alignment
        {
          type: 'url',
          label: 'Alineación',
          code: 'PERFORMANCE.OKRS_PERFORMANCE.OKRS_ALIGNMENT'
        }]
      },
      // Objectives
      {
        type: 'url',
        code: 'MODULE_PEFORMANCE.GOALS',
        label: 'Objetivos',
        icon: 'outline/OKRs',
        tooltip: {
          title: 'Objetivos',
          description: 'Gestiona y mide los objetivos de la compañía.'
        },
        url: '/tbd'
      },
      // Feedback
      {
        type: 'url',
        code: 'PERFORMANCE.FEEDBACK',
        label: 'Feedback',
        icon: 'outline/AppsComments',
        tooltip: {
          title: 'Feedback',
          description: 'Mide los procesos de feedback de tu compañía.'
        }
      },
      // PID
      {
        type: 'url',
        code: 'PERFORMANCE.PID',
        label: 'Plan individual de desarrollo',
        icon: 'outline/TrendingUp',
        tooltip: {
          title: 'Plan individual de desarrollo',
          description: 'Crea procesos para cerrar brechas y promover el crecimiento.'
        }
      },
      // Succession plan
      {
        type: 'url',
        code: 'PERFORMANCE.SUCCESSION_PLAN',
        label: 'Plan de sucesión',
        icon: 'outline/SuccessionPlan',
        tooltip: {
          title: 'Plan de sucesión',
          description: 'Crea planes de acción para futuras vacantes.'
        }
      }]
    },
    // Climate
    {
      type: 'module',
      code: 'CLIMATE_ENGAGEMENT',
      label: 'Clima',
      decoratedIcon: true,
      arrowDefault: true,
      icon: 'logos/Climate',
      tooltip: {
        title: 'Clima',
        description: 'Mide la cultura y satisfacción de tu equipo.'
      },
      children: [
      // Evaluations
      {
        type: 'url',
        code: 'CLIMATE_ENGAGEMENT.CLIMATE',
        label: 'Evaluaciones de clima',
        icon: 'outline/Presentation',
        tooltip: {
          title: 'Evaluaciones de clima',
          description: 'Monitorea el rendimiento de tu equipo.'
        }
      },
      // Short surveys
      {
        type: 'url',
        code: 'CLIMATE_ENGAGEMENT.SURVEY',
        label: 'Encuestas cortas',
        icon: 'outline/File',
        tooltip: {
          title: 'Encuestas cortas',
          description: 'Recolecta información de manera rápida y sencilla.'
        }
      },
      // Other evaluations
      {
        type: 'url',
        // pending
        code: 'CLIMATE_ENGAGEMENT.OTHERS',
        label: 'Otras encuestas',
        icon: 'outline/Note',
        tooltip: {
          title: 'Otras encuestas',
          description: 'Evaluaciones adicionales como riesgos psicosociales.'
        }
      }]
    },
    // Learning
    {
      type: 'module',
      code: 'E-Learning',
      label: 'Aprendizaje',
      decoratedIcon: true,
      arrowDefault: true,
      icon: 'logos/Learning',
      tooltip: {
        title: 'Aprendizaje',
        description: 'Gestiona estrategias de aprendizaje y potencia las habilidades de tus equipos.'
      },
      children: [
      // Content
      {
        type: 'dropdown',
        code: 'E-Learning.ML03',
        label: 'Contenido',
        icon: 'outline/BookOpen',
        tooltip: {
          title: 'Contenido',
          description: 'Gestiona los cursos, rutas de aprendizaje y otros recursos.'
        },
        children: [
        // Self courses
        {
          type: 'url',
          label: 'Cursos propios',
          code: 'E-Learning.ML03.ML031',
          url: '/org/[orgSlug]/panel/content/courses/'
        },
        // Paths
        {
          type: 'url',
          label: 'Rutas de aprendizaje',
          code: 'E-Learning.ML03.ML032',
          url: '/org/[orgSlug]/panel/content/paths/'
        },
        // Live sessions
        {
          type: 'url',
          label: 'Sesiones en vivo',
          code: 'MODULE_LEARNING.CONTENT.LIVE_SESSIONS',
          url: '/org/[orgSlug]/panel/content/lives/'
        },
        // Documents
        {
          type: 'url',
          label: 'Documentos',
          code: 'MODULE_LEARNING.CONTENT.DOCUMENTS',
          url: '/org/[orgSlug]/panel/content/documents/'
        },
        // Catalog
        {
          type: 'url',
          label: 'Catálogo',
          code: 'MODULE_LEARNING.CONTENT.CATALOG',
          url: '/org/[orgSlug]/panel/content/catalog/'
        },
        // Projects
        {
          type: 'url',
          label: 'Proyectos',
          code: 'E-Learning.ML03.ML033',
          url: '/org/[orgSlug]/panel/content/projects/'
        },
        // Certificates
        {
          type: 'url',
          label: 'Certificados',
          code: 'MODULE_LEARNING.CONTENT.CERTIFICATES',
          url: '/org/[orgSlug]/panel/content/certificates/'
        }]
      },
      // Skill XP Evolution
      {
        type: 'url',
        code: 'E-Learning.ML04',
        label: 'Skill XP Evolution',
        icon: 'outline/SkillXp',
        tooltip: {
          title: 'Skill XP Evolution',
          description: 'Mide las habilidades de cada persona.'
        },
        url: '/org/[orgSlug]/panel/skill-xp-evolution/'
      },
      // Gamification
      {
        type: 'dropdown',
        code: 'E-Learning.ML02',
        label: 'Gamificación',
        icon: 'outline/CompassRanking',
        tooltip: {
          title: 'Gamificación',
          description: 'Crear, gestionar y premia logros de aprendizaje.'
        },
        url: '/org/[orgSlug]/panel/gamification/',
        children: [
        // Progress
        {
          type: 'url',
          label: 'Logros',
          code: 'E-Learning.ML02.ML021',
          url: '/org/[orgSlug]/panel/gamification/progress/'
        },
        // Rewards
        {
          type: 'url',
          label: 'Recompensas',
          code: 'E-Learning.ML02.ML022',
          url: '/org/[orgSlug]/panel/gamification/rewards/'
        }]
      },
      // Store
      {
        type: 'dropdown',
        code: 'MODULE_LEARNING.STORE',
        label: 'Tienda',
        icon: 'outline/ShoppingCart',
        tooltip: {
          title: 'Tienda',
          description: 'Personaliza tu tienda virtual de contenido.'
        },
        children: [
        // Configuration
        {
          type: 'url',
          code: 'MODULE_LEARNING.STORE.CONFIGURATION',
          label: 'Configuración',
          url: "/org/[orgSlug]/panel/mi-tienda"
        },
        // E-commerce paths
        {
          type: 'url',
          code: 'MODULE_LEARNING.STORE.ECOMMERCE_PATHS',
          label: 'Rutas E-commerce',
          url: '/org/[orgSlug]/panel/content/courses/'
        }]
      },
      // Analytics
      {
        type: 'dropdown',
        code: 'MODULE_LEARNING.ANALYTICS',
        label: 'Analítica',
        icon: 'outline/Stats',
        tooltip: {
          title: 'Analítica',
          description: 'Monitorea tus procesos y genera reportes.'
        },
        children: [
        // Statistics
        {
          type: 'url',
          code: 'MODULE_LEARNING.ANALYTICS.STATISTICS',
          label: 'Estadísticas',
          url: '/org/[orgSlug]/panel/estadisticas/'
        },
        // Reports
        {
          type: 'url',
          code: 'MODULE_LEARNING.ANALYTICS.REPORTS',
          label: 'Reportes',
          url: '/org/[orgSlug]/panel/reportes/'
        }]
      },
      // Integrations
      {
        type: 'url',
        code: 'E-Learning.ML01',
        label: 'Integraciones',
        icon: 'outline/Cube',
        tooltip: {
          title: 'Integraciones',
          description: 'Conecta la plataforma con otros sistemas.'
        },
        url: '/org/[orgSlug]/panel/integraciones/'
      }]
    },
    // Remote work
    {
      type: 'url',
      code: 'MODULE_REMOTE_WORK',
      label: 'Trabajo remoto',
      icon: 'logos/RemoteWork',
      decoratedIcon: true,
      tooltip: {
        title: 'Trabajo remoto',
        description: 'Monitorea las jornadas laborales desde casa.'
      }
    },
    // Recruitment
    {
      type: 'url',
      code: 'RECRUITING',
      label: 'Reclutamiento',
      icon: 'logos/Reclutamiento',
      decoratedIcon: true,
      tooltip: {
        title: 'Reclutamiento',
        description: 'Gestiona el reclutamiento y la selección del talento de tu compañía.'
      }
    }]
  }],
  FOOTER: [GENERAL_SETTINGS_MODULE, HELP_LINK_ADMIN]
};