var learningUrls = ['/org/[slug]/learn/', '/org/[slug]/learn/content-for-you/', '/org/[slug]/learn/my-courses/enrolled/', '/org/[slug]/learn/my-courses/tracks/', '/org/[slug]/learn/my-courses/notes/', '/org/[slug]/learn/studyplan/', '/org/[slug]/learn/mentorship/', '/org/[slug]/learn/skill-xp-evolution/pending/', '/org/[slug]/learn/skill-xp-evolution/solved/', '/org/[slug]/learn/projects/', '/org/[slug]/learn/certificates/', '/org/[slug]/learn/content/',
// Special case for the knowledge hub additional urls
'/^\\/org\\/[a-zA-Z0-9-]+\\/learn\\/content(\\/[a-zA-Z0-9-]+)?\\/\\?$/'];
export var getLearningUrls = function getLearningUrls(organizationSlug) {
  return learningUrls.map(function (url) {
    if (url.includes('[slug]')) {
      return url.replace('[slug]', organizationSlug);
    }
    return url;
  });
};