import { useContext } from 'react';
import { useStore } from 'zustand';
import FeatureFlagContext from "../../context/FeatureFlagContext";
var useFeatureFlagStoreContext = function useFeatureFlagStoreContext(selector) {
  var featureFlagStoreContext = useContext(FeatureFlagContext);
  if (!featureFlagStoreContext) {
    throw new Error("useFeatureFlagStoreContext must be use within FeatureFlagProvider");
  }
  return useStore(featureFlagStoreContext, selector);
};
export default useFeatureFlagStoreContext;