import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var CompassLayoutsGetUserB2BVerificationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CompassLayoutsGetUserB2BVerification"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "userB2bVerification"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "email"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "message"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "code"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "orgSlug"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "role"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "assignedType"
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useCompassLayoutsGetUserB2BVerificationQuery__
 *
 * To run a query within a React component, call `useCompassLayoutsGetUserB2BVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassLayoutsGetUserB2BVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassLayoutsGetUserB2BVerificationQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompassLayoutsGetUserB2BVerificationQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(CompassLayoutsGetUserB2BVerificationDocument, options);
}
export function useCompassLayoutsGetUserB2BVerificationLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(CompassLayoutsGetUserB2BVerificationDocument, options);
}