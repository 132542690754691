/**
 * @description Set all flags as loading.
 */
export default function setFlagsAsLoading(flags) {
  var newFlags = flags.map(function (flag) {
    return {
      flagName: flag.flagName,
      apiVersion: flag.apiVersion,
      flagValue: false,
      error: undefined,
      loading: true
    };
  });
  return newFlags;
}