import { useCompassLayoutsGetOrganizationByLearningSlugQuery } from "./graphql/get-organization-by-learning-slug.v5.generated";
export var useCentralizedOrgSlug = function useCentralizedOrgSlug(_ref) {
  var _data$get_organizatio, _data$get_organizatio2;
  var organizationSlug = _ref.organizationSlug;
  var _useCompassLayoutsGet = useCompassLayoutsGetOrganizationByLearningSlugQuery({
      context: {
        clientName: 'v5'
      },
      variables: {
        organizationSlug: organizationSlug
      },
      skip: !organizationSlug
    }),
    data = _useCompassLayoutsGet.data,
    loading = _useCompassLayoutsGet.loading,
    error = _useCompassLayoutsGet.error;
  return {
    centralizedOrgSlug: (_data$get_organizatio = data === null || data === void 0 ? void 0 : (_data$get_organizatio2 = data.get_organization_by_learning_slug) === null || _data$get_organizatio2 === void 0 ? void 0 : _data$get_organizatio2.slug) !== null && _data$get_organizatio !== void 0 ? _data$get_organizatio : '',
    loading: loading,
    error: !!error
  };
};