import { customSessionStorage } from '@crehana/utils';
import { fetcher } from "./fetcher";

/**
 * Custom hook for talent router navigation.
 * This hook provides a function to navigate to a specific URL with the user's email and url.
 * First:
 * Checks if the user is logged in using the SAML URL appending the url you want to
 * visit using as queryparam (This lets the server save the url you are trying to visit).
 * Second:
 * if it is, it redirects to the acsendo-idp/login URL and that will redirect the user to the
 * previously saved URL value you were trying to visit.
 *
 * Note: The URLS name are kinda confusing, but is how it is implemented
 */
var useTalentRouter = function useTalentRouter(_ref) {
  var isProduction = _ref.isProduction;
  var navigateTo = function navigateTo(_ref2) {
    var emailUser = _ref2.emailUser,
      url = _ref2.url;
    fetcher("/acsendo-idp/saml/auth?nexturl=".concat(url), {
      body: JSON.stringify({
        emailUser: emailUser
      }),
      method: 'POST'
    }).then(function (res) {
      var userIsLoggedIn = res === 200;
      if (userIsLoggedIn) {
        // Careful. The QA environment for talent is docker-demo.acsendo Do not change it to docker-pruebas.acsendo.
        var ENDPOINT = isProduction ? 'https://docker-hr.acsendo.com' : 'https://docker-demo.acsendo.com';
        customSessionStorage.removeItem('s_user_roles');
        customSessionStorage.removeItem('s_user_modules');
        customSessionStorage.removeItem('s_home_company');
        customSessionStorage.removeItem('s_user_modules_admin');
        window.parent.location.href = "".concat(ENDPOINT, "/acsendo-idp/login");
      }
    })["catch"](function (e) {
      return console.error(e);
    });
  };
  return {
    navigateTo: navigateTo
  };
};
export default useTalentRouter;