import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @description Set a flag in the flags object.
 * If the flag already exists, it will be replaced.
 * If the flag does not exist, it will be added.
 */
export default function setFlagInObject(flags, setFlag) {
  return Object.assign({}, flags, _defineProperty({}, setFlag.flagName, {
    flagName: setFlag.flagName,
    flagValue: setFlag.flagValue,
    loading: setFlag.loading,
    error: setFlag.error
  }));
}