import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{
  primaryColor?: string;
  secondaryColor?: string;
}>`
  body {
    --color-primary-dark: ${props => props.primaryColor};
    --color-primary-main: ${props => props.primaryColor};
    --color-primary-light: ${props => props.primaryColor};
    --color-primary-lighter: ${props => props.primaryColor};

    --color-secondary-lighter: ${props => props.secondaryColor};
    --color-secondary-light: ${props => props.secondaryColor};
    --color-secondary-main: ${props => props.secondaryColor};
    --color-secondary-dark: ${props => props.secondaryColor};

    --color-primary-light-500: ${props => props.primaryColor};
    --color-neutral-dark-200: ${props => props.primaryColor + '1A'};
  }
`;
