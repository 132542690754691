import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/**
 * @description Transform the basic flags to a flag object. This is used to initialize the store with the flags.
 */
export default function transformBasicFlagToFlagObject() {
  var basicFlags = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return basicFlags.map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      flagKey = _ref2[0],
      apiVersion = _ref2[1];
    var flag = {
      flagName: flagKey,
      flagValue: false,
      loading: true,
      error: undefined,
      apiVersion: apiVersion
    };
    return flag;
  });
}