import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import { TRANSLATION_KEY } from "./constants";
import locales from "./i18n";
var useAdminTranslations = function useAdminTranslations() {
  var _useTranslation = useTranslation(TRANSLATION_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    translationContextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return translationContextValue;
};
export default useAdminTranslations;