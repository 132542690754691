import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var GetFeatureFlagByNameKeyQueryDocument = {
  kind: 'Document',
  definitions: [{
    kind: 'OperationDefinition',
    operation: 'query',
    name: {
      kind: 'Name',
      value: 'GetFeatureFlagByNameKeyQuery'
    },
    variableDefinitions: [{
      kind: 'VariableDefinition',
      variable: {
        kind: 'Variable',
        name: {
          kind: 'Name',
          value: 'name_key'
        }
      },
      type: {
        kind: 'NonNullType',
        type: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'String'
          }
        }
      }
    }, {
      kind: 'VariableDefinition',
      variable: {
        kind: 'Variable',
        name: {
          kind: 'Name',
          value: 'organization_id'
        }
      },
      type: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'Int'
        }
      }
    }],
    selectionSet: {
      kind: 'SelectionSet',
      selections: [{
        kind: 'Field',
        name: {
          kind: 'Name',
          value: 'feature_flag'
        },
        arguments: [{
          kind: 'Argument',
          name: {
            kind: 'Name',
            value: 'name_key'
          },
          value: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'name_key'
            }
          }
        }, {
          kind: 'Argument',
          name: {
            kind: 'Name',
            value: 'organization_id'
          },
          value: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'organization_id'
            }
          }
        }],
        selectionSet: {
          kind: 'SelectionSet',
          selections: [{
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'category'
            }
          }, {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'name_key'
            }
          }, {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'namespace_key'
            }
          }, {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'value'
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useGetFeatureFlagByNameKeyQueryQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagByNameKeyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagByNameKeyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagByNameKeyQueryQuery({
 *   variables: {
 *      name_key: // value for 'name_key'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetFeatureFlagByNameKeyQueryQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(GetFeatureFlagByNameKeyQueryDocument, options);
}
export function useGetFeatureFlagByNameKeyQueryLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(GetFeatureFlagByNameKeyQueryDocument, options);
}