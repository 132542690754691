// WIP: This import is legacy code
import { useFeatureFlagByNameKey } from '@crehana/compass.knowledgehub/hooks';
import { useCompassLayoutsGetOrganizationConfigQuery } from "./graphql/compass-layouts-get-organization-config.b2b.generated";
export var useConfiguration = function useConfiguration(_ref) {
  var _data$organization, _data$organization2;
  var organizationSlug = _ref.organizationSlug;
  var _useCompassLayoutsGet = useCompassLayoutsGetOrganizationConfigQuery({
      variables: {
        slug: organizationSlug
      },
      context: {
        clientName: 'b2b'
      },
      skip: !organizationSlug
    }),
    data = _useCompassLayoutsGet.data,
    loading = _useCompassLayoutsGet.loading;
  var _useFeatureFlagByName = useFeatureFlagByNameKey({
      nameKey: 'MARKETPLACE_DISPLAY',
      organizationId: (data === null || data === void 0 ? void 0 : (_data$organization = data.organization) === null || _data$organization === void 0 ? void 0 : _data$organization.originalId) || -1,
      skip: !(data !== null && data !== void 0 && (_data$organization2 = data.organization) !== null && _data$organization2 !== void 0 && _data$organization2.originalId) || loading
    }),
    canUseShop = _useFeatureFlagByName.activatedFlag;
  return {
    organizationSlug: organizationSlug,
    canUseShop: canUseShop
  };
};