import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import { CROSS_PLATFORM_SIDEBAR_COLLABORATOR_TRANSLATION_KEY } from "./constants";
import locales from "./i18n";
var useCollaboratorTranslations = function useCollaboratorTranslations() {
  var _useTranslation = useTranslation(CROSS_PLATFORM_SIDEBAR_COLLABORATOR_TRANSLATION_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    contextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return contextValue;
};
export default useCollaboratorTranslations;