import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useEffect, useMemo, useRef } from 'react';
import { useDebugger } from "../../../debugger";
import { SIDEBAR_TYPE } from "../../../types";
import useTalentRouter from "../../../../shared/hooks/useTalentRouter";
import { findMenuLinkByCode } from "../../utils";
import { FULL_SUITE_ADMIN_LINKS, FULL_SUITE_COLLABORATOR_LINKS } from "../data/full-suite-links";
import { addAdditionalData, arrayDifference, createMenuLinks, excludeLinks, getAllCodes } from "../data/utils";
import { getAdminLearningUrls, getAnalyticUrls, getGeneralConfigUrls, getManagementUrls } from "./admin-urls";
import { getLearningUrls } from "./urls";
import { useCollaboratorTranslations } from "./use-collaborator-translations";
export var useMenuLinks = function useMenuLinks(props) {
  var currentModule = props.currentModule,
    talentModules = props.talentModules,
    organizationSlug = props.organizationSlug,
    handleUrl = props.handleUrl,
    pathname = props.pathname,
    productPlatform = props.productPlatform,
    userEmail = props.userEmail,
    sidebarType = props.sidebarType,
    isProduction = props.isProduction,
    setCurrentModule = props.setCurrentModule,
    loadingTalentModules = props.loadingTalentModules;
  var _useCollaboratorTrans = useCollaboratorTranslations(),
    t = _useCollaboratorTrans.t;
  var talentRouter = useTalentRouter({
    isProduction: isProduction
  });

  // Get the the links for the Menu type
  var BASE_LINKS = sidebarType === 'collaborator' ? FULL_SUITE_COLLABORATOR_LINKS : FULL_SUITE_ADMIN_LINKS;
  var MENU_LINKS = BASE_LINKS.MENU;
  var FOOTER_LINKS = BASE_LINKS.FOOTER;
  var CODES_TO_EXCLUDE = useMemo(function () {
    return arrayDifference(
    // All products codes
    getAllCodes(MENU_LINKS),
    // Allowed products codes
    Object.keys(talentModules));
  }, [talentModules, MENU_LINKS]);
  var _useDebugger = useDebugger(),
    shouldFilter = _useDebugger.shouldFilter;
  var productsLinks = useMemo(function () {
    return createMenuLinks({
      links: addAdditionalData(shouldFilter ? excludeLinks(MENU_LINKS, CODES_TO_EXCLUDE) : MENU_LINKS, talentModules),
      t: t,
      orgSlug: organizationSlug,
      orgSlugPatternToReplace: '[orgSlug]'
    });
  }, [MENU_LINKS, CODES_TO_EXCLUDE, talentModules, t, organizationSlug, shouldFilter]);
  var hasSettedModuleOnFirstLoad = useRef(false);
  // Check on first page load for selectedModule. This is important and useful when the user gets redirected to this page from another page
  useEffect(function () {
    // Do nothing if ...
    if (
    // Data is loading
    loadingTalentModules ||
    // Module has been set already
    hasSettedModuleOnFirstLoad.current) {
      return;
    }

    // Logic for collaborator
    if (sidebarType === SIDEBAR_TYPE.collaborator) {
      var _findMenuLinkByCode, _findMenuLinkByCode$c;
      var learningUrls = getLearningUrls(organizationSlug);
      if ((_findMenuLinkByCode = findMenuLinkByCode(productsLinks, 'MODULE_LEARNING')) !== null && _findMenuLinkByCode !== void 0 && (_findMenuLinkByCode$c = _findMenuLinkByCode.children) !== null && _findMenuLinkByCode$c !== void 0 && _findMenuLinkByCode$c.length && learningUrls.find(function (r) {
        var regExp = new RegExp(r);
        return regExp.test(pathname);
      })) {
        setCurrentModule(findMenuLinkByCode(productsLinks, 'MODULE_LEARNING'));
        hasSettedModuleOnFirstLoad.current = true;
      }
    }

    // Logic for admin
    var currentModule = defineCurrentModuleForAdminUser({
      organizationSlug: organizationSlug,
      pathname: pathname,
      productsLinks: productsLinks
    });
    if (currentModule) {
      setCurrentModule(currentModule);
      hasSettedModuleOnFirstLoad.current = true;
    }
  }, [loadingTalentModules, productsLinks, pathname, setCurrentModule, organizationSlug, sidebarType]);
  var onClickItem = function onClickItem(route, openInNewTab) {
    if (productPlatform === 'talent') {
      talentRouter.navigateTo({
        url: route,
        emailUser: userEmail
      });
    } else {
      // Handle external link url to talent platform
      if (route.startsWith('https')) {
        if (openInNewTab) {
          window.open(route, '_blank');
        } else {
          window.location.href = route;
        }
      }
      // Handle local routing
      else {
        var routeSlug = route.replace('[orgSlug]', organizationSlug);
        if (handleUrl) {
          handleUrl(routeSlug);
        } else {
          window.location.href = routeSlug;
        }
      }
    }
  };
  var currentSingleRoute = useMemo(function () {
    var flatUrls = _getFlattenLinks(productsLinks);

    // Get current collaborator link
    if (sidebarType === SIDEBAR_TYPE.collaborator) {
      var tracksLink = flatUrls.find(function (url) {
        return url.code === 'LEARNING.COURSES.PATHS';
      });
      var trackSpecialCasePattern = new RegExp('^/org/[a-zA-Z0-9-]+/learn/my-courses/tracks/[a-zA-Z0-9-]+/$');
      if (trackSpecialCasePattern.test(pathname)) return tracksLink;

      // It's very important to check first the special case for the knowledge hub
      // The thing here is they want a temporary way to access to knowledge hub
      // by other URLS that match the pattern above
      var knowledgeHubSpecialCasePattern = new RegExp('^/org/[a-zA-Z0-9-]+/learn/content/[a-zA-Z0-9-]+/$');
      var knowledgeHubLink = flatUrls.find(function (url) {
        return url.code === 'LEARNING.CATALOG';
      });
      if (knowledgeHubSpecialCasePattern.test(pathname)) {
        return knowledgeHubLink;
      }
      return flatUrls.find(function (link) {
        return typeof link.url === 'string' && link.url.includes(pathname.replace('[orgSlug]', organizationSlug));
      });
    }

    // Get admin link logic

    // To logic to get the current admin links differs from collaborator
    // This is IMPORTANT because
    return flatUrls.find(function (link) {
      var _link$url;
      return ((_link$url = link.url) === null || _link$url === void 0 ? void 0 : _link$url.length) &&
      // link.url.includes(pathname.replace('[orgSlug]', organizationSlug)),
      pathname.replace('[orgSlug]', organizationSlug).startsWith(link.url);
    }
    // link.url.startsWith(pathname.replace('[orgSlug]', organizationSlug)),
    );
  }, [productsLinks, pathname, organizationSlug, sidebarType]);
  var footerCodestoExclude = arrayDifference(getAllCodes(FOOTER_LINKS),
  // Allowed products
  Object.keys(talentModules));
  var footerLinks = createMenuLinks({
    links: excludeLinks((currentModule === null || currentModule === void 0 ? void 0 : currentModule.code) === 'MODULE_GENERAL_CONFIG' ? FOOTER_LINKS.filter(function (e) {
      return e.code !== 'MODULE_GENERAL_CONFIG';
    }) : FOOTER_LINKS, footerCodestoExclude),
    t: t,
    orgSlug: organizationSlug,
    orgSlugPatternToReplace: '[orgSlug]'
  });
  return {
    menuLinks: productsLinks,
    onClickItem: onClickItem,
    currentSingleRoute: currentSingleRoute,
    footerLinks: footerLinks
  };
};
var _getFlattenLinks = function getFlattenLinks(links) {
  return links.reduce(function (flattenLinks, link) {
    var currentLink = _objectSpread({}, link);
    if (currentLink.children) {
      var flattenedChildren = _getFlattenLinks(_toConsumableArray(currentLink.children));
      return [].concat(_toConsumableArray(flattenLinks), [currentLink], _toConsumableArray(flattenedChildren));
    }
    return [].concat(_toConsumableArray(flattenLinks), [currentLink]);
  }, []);
};

/**
 * Determines the current module for an admin user based on the current pathname.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.organizationSlug - The slug of the organization.
 * @param {string} params.pathname - The current pathname.
 * @param {IMenuLink[]} params.productsLinks - The array of product links.
 * @returns {IMenuLink | null} The current module link if found, otherwise null.
 */
var defineCurrentModuleForAdminUser = function defineCurrentModuleForAdminUser(_ref) {
  var organizationSlug = _ref.organizationSlug,
    pathname = _ref.pathname,
    productsLinks = _ref.productsLinks;
  // Define an array of URL checkers, each with a getter function and a corresponding module code
  var urlCheckers = [{
    getter: getAnalyticUrls,
    code: 'MAIN_ADMIN_ANALYTICS'
  }, {
    getter: getAdminLearningUrls,
    code: 'E-Learning'
  }, {
    getter: getManagementUrls,
    code: 'ADMIN'
  }, {
    getter: getGeneralConfigUrls,
    code: 'MODULE_GENERAL_CONFIG'
  }];

  // Iterate through the URL checkers
  for (var _i = 0, _urlCheckers = urlCheckers; _i < _urlCheckers.length; _i++) {
    var _urlCheckers$_i = _urlCheckers[_i],
      getter = _urlCheckers$_i.getter,
      code = _urlCheckers$_i.code;
    // Check if the current pathname includes any of the URLs returned by the getter function
    if (getter(organizationSlug).some(function (url) {
      return pathname.includes(url);
    })) {
      // If a match is found, return the corresponding menu link
      return findMenuLinkByCode(productsLinks, code);
    }
  }

  // If no match is found, return null
  return null;
};