import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var CompassLayoutsGetAllowedTalentModulesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CompassLayoutsGetAllowedTalentModules"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "email"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "organizationSlug"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "get_allowed_modules"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "email"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "organization_slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "organizationSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "employee_id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modules"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "label"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "code"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "url"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "submodules"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "label"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "code"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "submodules"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "label"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "code"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modules_admin"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "label"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "code"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "url"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "submodules"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "label"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "code"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "submodules"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "label"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "code"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "state"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "boss_full_name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "job"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "division"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "subsidiary"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "is_boss"
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useCompassLayoutsGetAllowedTalentModulesQuery__
 *
 * To run a query within a React component, call `useCompassLayoutsGetAllowedTalentModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassLayoutsGetAllowedTalentModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassLayoutsGetAllowedTalentModulesQuery({
 *   variables: {
 *      email: // value for 'email'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useCompassLayoutsGetAllowedTalentModulesQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(CompassLayoutsGetAllowedTalentModulesDocument, options);
}
export function useCompassLayoutsGetAllowedTalentModulesLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(CompassLayoutsGetAllowedTalentModulesDocument, options);
}