import { useFeatureFlagByNameKey } from '@crehana/compass.knowledgehub/hooks';
var useHigherEducation = function useHigherEducation(_ref) {
  var organizationId = _ref.organizationId;
  var _useFeatureFlagByName = useFeatureFlagByNameKey({
      nameKey: 'MARKETPLACE_DISPLAY',
      organizationId: organizationId,
      skip: !organizationId
    }),
    activatedFlag = _useFeatureFlagByName.activatedFlag,
    loading = _useFeatureFlagByName.loading;
  return {
    isHigherEducationActive: activatedFlag,
    isLoadingHigherEducation: loading
  };
};
export default useHigherEducation;