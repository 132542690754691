import { useQuery } from '@apollo/client';
var COMPASS_LAYOUTS_B2B_VERIFICATION_QUERY = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "CompassLayoutsB2BVerificationQuery" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "email" } }, type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "userB2bVerification" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "email" }, value: { kind: "Variable", name: { kind: "Name", value: "email" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "message" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "code" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "type" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "orgSlug" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "role" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "assignedType" }, arguments: [], directives: [] }] } }] } }], loc: { start: 0, end: 173, source: { body: "query CompassLayoutsB2BVerificationQuery($email: String!) {\n  userB2bVerification(email: $email) {\n    message\n    code\n    type\n    orgSlug\n    role\n    assignedType\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useOrganizationSlug = function useOrganizationSlug(_ref) {
  var _data$userB2bVerifica, _data$userB2bVerifica2, _data$userB2bVerifica3, _data$userB2bVerifica4, _data$userB2bVerifica5, _data$userB2bVerifica6;
  var email = _ref.email;
  var _useQuery = useQuery(COMPASS_LAYOUTS_B2B_VERIFICATION_QUERY, {
      variables: {
        email: email
      },
      context: {
        clientName: 'v4.b2b'
      },
      skip: !email
    }),
    data = _useQuery.data,
    loading = _useQuery.loading;
  return {
    organizationSlug: (_data$userB2bVerifica = data === null || data === void 0 ? void 0 : (_data$userB2bVerifica2 = data.userB2bVerification) === null || _data$userB2bVerifica2 === void 0 ? void 0 : _data$userB2bVerifica2.orgSlug) !== null && _data$userB2bVerifica !== void 0 ? _data$userB2bVerifica : '',
    myRole: (_data$userB2bVerifica3 = data === null || data === void 0 ? void 0 : (_data$userB2bVerifica4 = data.userB2bVerification) === null || _data$userB2bVerifica4 === void 0 ? void 0 : _data$userB2bVerifica4.role) !== null && _data$userB2bVerifica3 !== void 0 ? _data$userB2bVerifica3 : '',
    isB2BAllCatalog: (data === null || data === void 0 ? void 0 : (_data$userB2bVerifica5 = data.userB2bVerification) === null || _data$userB2bVerifica5 === void 0 ? void 0 : _data$userB2bVerifica5.assignedType) === 1,
    isB2BOnlyPanel: (data === null || data === void 0 ? void 0 : (_data$userB2bVerifica6 = data.userB2bVerification) === null || _data$userB2bVerifica6 === void 0 ? void 0 : _data$userB2bVerifica6.assignedType) === 2,
    isLoading: loading
  };
};
export default useOrganizationSlug;