import { FC, useCallback, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useMe } from '@crehana/auth';
import { useIsPlayRoomActivated } from '@crehana/compass.playroom';

import type { LoadChatScriptsProps } from '../../types';
import { customIntercomConfiguration } from '../../utils/constants';

const LoadIntercomChat: FC<React.PropsWithChildren<LoadChatScriptsProps>> = ({
  organizationInfo,
  autoboot = true,
}) => {
  const { me, loading } = useMe();

  const { boot, shutdown } = useIntercom();

  const {
    isActivated: isPlayroomActivated,
    loading: loadingPlayroom,
    error: playroomError,
  } = useIsPlayRoomActivated(me?.organization?.slug);

  const hasElevate: boolean =
    !!organizationInfo?.configuration?.hasStudioAvailable &&
    !!organizationInfo?.configuration?.canUploadCourses;

  const initialBoot = useCallback(() => {
    if (me) {
      boot({
        ...customIntercomConfiguration(hasElevate, isPlayroomActivated, me),
      });
    }
  }, [boot, hasElevate, isPlayroomActivated, me]);

  useEffect(() => {
    if (!loading || !loadingPlayroom) {
      try {
        initialBoot();
        if (!autoboot) {
          shutdown();
        }
      } catch (err) {
        console.error(err || playroomError);
      }
    }
  }, [
    autoboot,
    initialBoot,
    loading,
    loadingPlayroom,
    playroomError,
    shutdown,
  ]);

  return null;
};

export default LoadIntercomChat;
