import { FC } from 'react';
import * as React from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { APP_ID, APP_ID_QA } from '../../constants';
import { LoadChatScriptsProps } from '../../types';
import { isQA } from '../../utils/constants';
import LoadIntercomChat from './LoadIntercomChat';

const IntercomProviderHooks: FC<
  React.PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & LoadChatScriptsProps
  >
> = ({ organizationInfo, autoboot, children }) => {
  return (
    <IntercomProvider appId={isQA() ? APP_ID_QA : APP_ID} autoBoot>
      <LoadIntercomChat
        organizationInfo={organizationInfo}
        autoboot={autoboot}
      />
      {children}
    </IntercomProvider>
  );
};

export default IntercomProviderHooks;
