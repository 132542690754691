import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import Climate from '@crehana/compass.icons.logos/Climate';
import Learning from '@crehana/compass.icons.logos/Learning';
import Performance from '@crehana/compass.icons.logos/Performance';
import Recognition from '@crehana/compass.icons.logos/Recognition';
import Administrate from '@crehana/compass.icons.outline/Administrate';
import Stats from '@crehana/compass.icons.outline/Stats';
import { notEmpty } from '@crehana/utils';
import useTalentRouter from "../../../shared/hooks/useTalentRouter";
import { MODULE_CODE_ADMIN } from "../../types";
import { getAdminUrls, getAnalyticUrls, getHomeUrls, getLearningUrls } from "./urls";
import useAdminTranslations from "./useAdminTranslations";
import { useELearningModules } from "./useELearningModules";
import { Menu, useUtils } from "./utils";
var useAdminLinks = function useAdminLinks(_ref) {
  var _ref$talentAdminModul = _ref.talentAdminModules,
    talentAdminModules = _ref$talentAdminModul === void 0 ? [] : _ref$talentAdminModul,
    organizationSlug = _ref.organizationSlug,
    handleUrl = _ref.handleUrl,
    pathname = _ref.pathname,
    isLearningModuleActive = _ref.isLearningModuleActive,
    productPlatform = _ref.productPlatform,
    userEmail = _ref.userEmail,
    isProduction = _ref.isProduction,
    withAdminPanelNavigation = _ref.withAdminPanelNavigation;
  var _useAdminTranslations = useAdminTranslations(),
    t = _useAdminTranslations.t;
  var talentRouter = useTalentRouter({
    isProduction: isProduction
  });
  var _useUtils = useUtils({
      organizationSlug: organizationSlug,
      talentAdminModules: talentAdminModules,
      withAdminPanelNavigation: withAdminPanelNavigation
    }),
    getAdminLinks = _useUtils.getAdminLinks,
    getFooterLinks = _useUtils.getFooterLinks,
    getProductsLinks = _useUtils.getProductsLinks,
    getSubmodules = _useUtils.getSubmodules;
  var _useELearningModules = useELearningModules(organizationSlug),
    getELearningLinks = _useELearningModules.getELearningLinks;
  var _useState = useState(Menu.Products),
    _useState2 = _slicedToArray(_useState, 2),
    selectedModule = _useState2[0],
    setSelectedModule = _useState2[1];
  var analyticUrls = useMemo(function () {
    return getAnalyticUrls(organizationSlug);
  }, [organizationSlug]);
  var learningUrls = useMemo(function () {
    return getLearningUrls(organizationSlug);
  }, [organizationSlug]);
  var adminUrls = useMemo(function () {
    return getAdminUrls(organizationSlug);
  }, [organizationSlug]);

  /*
    Check on first page load for selectedModule.
    This is important and useful when the user gets redirected to this page from another page
  */
  useEffect(function () {
    var isHomeUrl = getHomeUrls(organizationSlug).find(function (url) {
      return url === pathname;
    });
    var pathModule = pathname.split('/').slice(0, 5).join('/');

    // The validation order here it's critical, do not change it or could lead to unexpected behavior
    if (isHomeUrl) {
      setSelectedModule(Menu.Products);
    } else if (analyticUrls.find(function (r) {
      return r.includes(pathModule);
    })) {
      setSelectedModule(Menu.Analytics);
    } else if (learningUrls.find(function (r) {
      return r.includes(pathModule);
    })) {
      setSelectedModule(Menu.Learning);
    } else if (adminUrls.find(function (r) {
      return r.includes(pathModule);
    })) {
      setSelectedModule(Menu.Admin);
    }
  }, [pathname, learningUrls, adminUrls, organizationSlug, analyticUrls]);
  var menuProductLinks = getProductsLinks({
    backendTalentModules: talentAdminModules,
    isLearningModuleActive: isLearningModuleActive
  });
  var isAnalyticsMenuActive = selectedModule === Menu.Analytics;
  var isRecognitionsMenuActive = selectedModule === Menu.Recognitions;
  var isPerformanceMenuActive = selectedModule === Menu.Performance;
  var isClimateMenuActive = selectedModule === Menu.Climate;
  var isLearningMenuActive = selectedModule === Menu.Learning;
  var isAdminMenuActive = selectedModule === Menu.Admin;
  var getModuleLabelByCode = function getModuleLabelByCode(code) {
    var _talentAdminModules$f;
    return (_talentAdminModules$f = talentAdminModules.find(function (m) {
      return m.code === code;
    })) === null || _talentAdminModules$f === void 0 ? void 0 : _talentAdminModules$f.label;
  };
  var currentHeader = useMemo(function () {
    if (isAnalyticsMenuActive) {
      return {
        title: getModuleLabelByCode(MODULE_CODE_ADMIN.MAIN_ADMIN_ANALYTICS) || t('MODULE_ANALYTICS'),
        icon: Stats
      };
    }
    if (isRecognitionsMenuActive) {
      return {
        title: getModuleLabelByCode(MODULE_CODE_ADMIN.RECOGNITION) || t('MODULE_RECOGNITION'),
        icon: Recognition
      };
    }
    if (isPerformanceMenuActive) {
      return {
        title: getModuleLabelByCode(MODULE_CODE_ADMIN.PERFORMANCE) || t('MODULE_PERFORMANCE'),
        icon: Performance
      };
    }
    if (isClimateMenuActive) {
      return {
        title: getModuleLabelByCode(MODULE_CODE_ADMIN.CLIMATE_ENGAGEMENT) || t('MODULE_CLIMATE'),
        icon: Climate
      };
    }
    if (isLearningMenuActive) {
      return {
        title: getModuleLabelByCode(MODULE_CODE_ADMIN.E_LEARNING) || t('MODULE_LEARNING'),
        icon: Learning
      };
    }
    if (isAdminMenuActive) {
      return {
        title: getModuleLabelByCode(MODULE_CODE_ADMIN.ADMIN) || t('MODULE_ADMIN'),
        icon: Administrate
      };
    }
    return;
  }, [t, isAnalyticsMenuActive, isClimateMenuActive, isPerformanceMenuActive, isRecognitionsMenuActive, isLearningMenuActive, isAdminMenuActive]);
  var onClickItem = function onClickItem(route) {
    var urlIsModule = Object.values(Menu).includes(route);
    if (urlIsModule) {
      setSelectedModule(route);
    } else {
      if (productPlatform === 'talent') {
        talentRouter.navigateTo({
          emailUser: userEmail,
          url: route
        });
      } else {
        // Handle external link url to talent platform
        if (route.startsWith('https')) {
          window.location.href = route;
        }
        // Handle local routing
        else {
          var routeSlug = route.replace('[slug]', organizationSlug);
          if (handleUrl) {
            handleUrl(routeSlug);
          } else {
            window.location.href = routeSlug;
          }
        }
      }
    }
  };
  var menuLinks = useMemo(function () {
    var _talentAdminModules$f2;
    var menuAnalyticsLinks = getSubmodules({
      backendModules: talentAdminModules,
      moduleCode: MODULE_CODE_ADMIN.MAIN_ADMIN_ANALYTICS
    });
    var menuRecognitionLinks = getSubmodules({
      backendModules: talentAdminModules,
      moduleCode: MODULE_CODE_ADMIN.RECOGNITION
    });
    var menuPerformanceLinks = getSubmodules({
      backendModules: talentAdminModules,
      moduleCode: MODULE_CODE_ADMIN.PERFORMANCE
    });
    var menuClimateLinks = getSubmodules({
      backendModules: talentAdminModules,
      moduleCode: MODULE_CODE_ADMIN.CLIMATE_ENGAGEMENT
    });
    var menuAdminLinks = getAdminLinks();
    var ELearningModules = ((_talentAdminModules$f2 = talentAdminModules.find(function (r) {
      return r.code === MODULE_CODE_ADMIN.E_LEARNING;
    })) === null || _talentAdminModules$f2 === void 0 ? void 0 : _talentAdminModules$f2.submodules) || [];
    var menuLearningLinks = isLearningModuleActive ? getELearningLinks(ELearningModules) : [];
    if (isAnalyticsMenuActive) return menuAnalyticsLinks;
    if (isRecognitionsMenuActive) return menuRecognitionLinks;
    if (isPerformanceMenuActive) return menuPerformanceLinks;
    if (isClimateMenuActive) return menuClimateLinks;
    if (isLearningMenuActive) return menuLearningLinks;
    if (isAdminMenuActive) return menuAdminLinks;
    return menuProductLinks;
  }, [getAdminLinks, getELearningLinks, getSubmodules, menuProductLinks, isAnalyticsMenuActive, isAdminMenuActive, isClimateMenuActive, isPerformanceMenuActive, isRecognitionsMenuActive, isLearningMenuActive, talentAdminModules, isLearningModuleActive]);

  // TODO: Need to add TALENT urls now to check
  var currentSingleRoute = useMemo(function () {
    var isHomeUrl = getHomeUrls(organizationSlug).find(function (url) {
      return url === pathname;
    });

    // Check if we are at home
    if (isHomeUrl) {
      return menuProductLinks[0];
    }
    var currentRoute = menuLinks === null || menuLinks === void 0 ? void 0 : menuLinks.map(function (_menuLink) {
      var _menuLink$nested;
      /**
       * Validate first submodules to mark as active
       * Instead validate module without children
       */
      // Check nested items
      if ((_menuLink$nested = _menuLink.nested) !== null && _menuLink$nested !== void 0 && _menuLink$nested.length) {
        var _menuLink$nested2;
        return (_menuLink$nested2 = _menuLink.nested) === null || _menuLink$nested2 === void 0 ? void 0 : _menuLink$nested2.find(function (_nestedMenuLink) {
          if (_nestedMenuLink.url) {
            var _nestedMenuLink$url;
            return ((_nestedMenuLink$url = _nestedMenuLink.url) === null || _nestedMenuLink$url === void 0 ? void 0 : _nestedMenuLink$url.includes(pathname)) || pathname.includes(_nestedMenuLink.url);
          }
        });
      }
      if (_menuLink.url === pathname || _menuLink.url && pathname.startsWith(_menuLink.url)) {
        return _menuLink;
      }
    }).filter(notEmpty)[0];
    return currentRoute;
  }, [menuLinks, pathname, menuProductLinks, organizationSlug]);
  var footerLinks = getFooterLinks({
    backendModules: talentAdminModules,
    selectedModule: selectedModule
  });
  return {
    backToUrl: currentHeader ? Menu.Products : '',
    header: currentHeader,
    menuLinks: menuLinks,
    currentSingleRoute: currentSingleRoute,
    isAdminMenuActive: isAdminMenuActive,
    footerLinks: footerLinks,
    onClickItem: onClickItem
  };
};
export default useAdminLinks;