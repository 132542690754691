var baseURL = '/org/[orgSlug]/learn';
export var COLLABORATOR_LEARNING_MODULE = {
  type: 'module',
  label: 'Aprendizaje',
  code: 'MODULE_LEARNING',
  icon: 'logos/Learning',
  decoratedIcon: true,
  arrowDefault: true,
  tooltip: {
    title: 'Aprendizaje',
    description: 'Gestiona tus cursos y cursos de capacitación.'
  },
  children: [
  // Home
  {
    type: 'url',
    code: 'LEARNING.HOME',
    label: 'MODULE_HOME',
    icon: 'outline/Home',
    tooltip: 'MODULE_HOME',
    url: baseURL + '/'
  },
  // Catalog - Knowledge Hub
  {
    type: 'url',
    code: 'LEARNING.CATALOG',
    label: 'LEARNING_LINK_KNOWLEDGE_HUB',
    icon: 'outline/Globe2',
    url: baseURL + '/content/',
    tooltip: {
      title: 'LEARNING_LINK_KNOWLEDGE_HUB',
      description: 'Descubre los nuevos contenidos de aprendizaje'
    }
  },
  // Courses - Dropdown
  {
    type: 'dropdown',
    code: 'LEARNING.COURSES',
    label: 'Mi contenido',
    icon: 'outline/Playsquare',
    children: [
    // Courses
    {
      type: 'url',
      code: 'LEARNING.COURSES.COURSES',
      // label: 'Cursos',
      label: 'LEARNING_LINK_ENROLLED_COURSES',
      url: baseURL + '/my-courses/enrolled/'
    },
    // Learning Paths
    {
      type: 'url',
      code: 'LEARNING.COURSES.PATHS',
      label: 'LEARNING_LINK_PATHS',
      url: baseURL + '/my-courses/tracks/'
    },
    // Live Sessions
    {
      type: 'url',
      code: 'LEARNING.COURSES.LIVE_SESSIONS',
      label: 'LEARNING_LINK_LIVE_SESSIONS',
      url: baseURL + '/my-courses/live-sessions/'
    },
    // Notes
    {
      type: 'url',
      code: 'LEARNING.COURSES.NOTES',
      label: 'LEARNING_LINK_NOTES',
      url: baseURL + '/my-courses/notes/'
    }]
  },
  // Gamification
  {
    type: 'dropdown',
    code: 'LEARNING.GAMIFICATION',
    label: 'LEARNING_LINK_GAMIFICATION',
    icon: 'outline/CompassRanking',
    tooltip: {
      title: 'LEARNING_LINK_GAMIFICATION',
      description: 'LEARNING_LINK_TOOLTIP_GAMIFICATION_DESCRIPTION'
    },
    children: [
    // Achievements
    {
      type: 'url',
      code: 'LEARNING.GAMIFICATION.ACHIEVEMENTS',
      label: 'LEARNING_LINK_ACCHIEVEMENTS',
      url: baseURL + '/playroom/achievements/'
    },
    // Rewards
    {
      type: 'url',
      code: 'LEARNING.GAMIFICATION.REWARDS',
      label: 'LEARNING_LINK_REWARDS',
      url: baseURL + '/playroom/rewards/'
    }]
  },
  // Study Plan
  {
    type: 'url',
    code: 'LEARNING.STUDY_PLAN',
    label: 'LEARNING_LINK_STUDY_PLAN',
    icon: 'outline/Calendar5',
    url: baseURL + '/studyplan/',
    tooltip: {
      title: 'LEARNING_LINK_STUDY_PLAN',
      description: 'LEARNING_LINK_TOOLTIP_STUDY_PLAN'
    }
  },
  // Mentorship
  {
    type: 'url',
    code: 'LEARNING.MENTORSHIP',
    label: 'LEARNING_LINK_MENTORSHIP',
    icon: 'outline/Mentorship',
    url: baseURL + '/mentorship/',
    tooltip: {
      title: 'LEARNING_LINK_MENTORSHIP',
      description: 'LEARNING_LINK_TOOLTIP_MENTORSHIP'
    }
  },
  // Skill XP Evolution
  {
    type: 'dropdown',
    code: 'LEARNING.SKILL_XP_EVOLUTION',
    label: 'LEARNING_LINK_SKILL_XP_EVOLUTION',
    icon: 'outline/SkillXp',
    tooltip: {
      title: 'LEARNING_LINK_SKILL_XP_EVOLUTION',
      description: 'LEARNING_LINK_TOOLTIP_SKILL_XP_EVOLUTION_DESCRIPTION'
    },
    children: [
    // Progress
    {
      type: 'url',
      code: 'LEARNING.SKILL_XP_EVOLUTION.PROGRESS',
      label: 'Progreso',
      url: baseURL + '/skill-xp-evolution/pending/'
    },
    // Rewards
    {
      type: 'url',
      code: 'LEARNING.SKILL_XP_EVOLUTION.REWARDS',
      label: 'Recompensas',
      url: baseURL + '/skill-xp-evolution/solved/'
    }]
  },
  // Projects
  {
    type: 'url',
    code: 'LEARNING.PROJECTS',
    label: 'LEARNING_LINK_PROJECTS',
    icon: 'outline/Projects',
    url: baseURL + '/projects/',
    tooltip: {
      title: 'LEARNING_LINK_PROJECTS',
      description: 'LEARNING_LINK_TOOLTIP_PROJECTS_DESCRIPTION'
    }
  },
  // Diplomas
  {
    type: 'url',
    code: 'LEARNING.DIPLOMAS',
    label: 'LEARNING_LINK_DIPLOMA',
    icon: 'outline/Diploma',
    url: baseURL + '/certificates/',
    tooltip: {
      title: 'LEARNING_LINK_DIPLOMA',
      description: 'LEARNING_LINK_TOOLTIP_DIPLOMA_DESCRIPTION'
    }
  }]
};