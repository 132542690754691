import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["data", "loading", "refetch"];
import { AccessLevelEnum } from "../../generated/graphql/types";
import { useOrganizationAccessLevelQueryQuery } from "./graphql/OrganizationAccessLevelQuery.v5.generated";
var useOrganizationAccessLevel = function useOrganizationAccessLevel(_ref) {
  var _data$content_organiz;
  var orgId = _ref.orgId,
    skip = _ref.skip;
  var _useOrganizationAcces = useOrganizationAccessLevelQueryQuery({
      context: {
        clientName: 'v5'
      },
      variables: {
        input: {
          organization_id: orgId
        }
      },
      skip: skip,
      notifyOnNetworkStatusChange: true
    }),
    data = _useOrganizationAcces.data,
    loading = _useOrganizationAcces.loading,
    refetch = _useOrganizationAcces.refetch,
    courseEnrollmentsQuery = _objectWithoutProperties(_useOrganizationAcces, _excluded);
  var isPureLMSOrganization = (data === null || data === void 0 ? void 0 : (_data$content_organiz = data.content_organization.organization_access_level) === null || _data$content_organiz === void 0 ? void 0 : _data$content_organiz.access_level_enum) === AccessLevelEnum.CrehanaElevate;
  return _objectSpread({
    refetch: refetch,
    loading: loading,
    isPureLMSOrganization: isPureLMSOrganization
  }, courseEnrollmentsQuery);
};
export default useOrganizationAccessLevel;